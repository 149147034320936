import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import React, { useCallback, useState } from "react";

interface ClaimTypeSelectProps {
  claimTypes: string[];
  selectedClaimType: string | null;
  setSelectedClaimType: (selectedClaimType: string | null) => void;
  disabled?: boolean;
  className?: string;
}

/**
 * @description Claim type select component
 * @param {string[]} claimTypes - The claim types to select from.
 * @param {string | null} selectedClaimType - The selected claim type.
 * @param {function} setSelectedClaimType - Function to set the selected claim type.
 * @param {boolean} disabled - Whether the claim type select is disabled.
 */
const ClaimTypeSelect: React.FC<ClaimTypeSelectProps> = ({
  claimTypes = [],
  selectedClaimType,
  setSelectedClaimType,
  disabled = false,
  className,
}) => {
  const [open, setOpen] = useState(false);

  const handleClaimTypeSelect = useCallback(
    (claimType: string) => {
      setSelectedClaimType(selectedClaimType === claimType ? null : claimType);
      setOpen(false); // Close popover after selection
    },
    [selectedClaimType, setSelectedClaimType],
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-[40%] justify-between", className)}
          disabled={disabled}
        >
          <div className="flex items-center">
            {selectedClaimType ? (
              <span className="text-sm font-medium">{selectedClaimType}</span>
            ) : (
              <span>Select Claim Type</span>
            )}
          </div>
          <ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        {claimTypes.map((type) => (
          <Button
            key={type}
            variant="ghost"
            className="w-full justify-start"
            onClick={() => handleClaimTypeSelect(type)}
          >
            <div className="flex items-center">
              <span>{type}</span>
            </div>
          </Button>
        ))}
      </PopoverContent>
    </Popover>
  );
};

export default React.memo(ClaimTypeSelect);
