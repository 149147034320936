import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import React, { useCallback, useState } from "react";

interface CountryCodeSelectProps {
  countryCodes: string[];
  selectedCountryCodes: string[];
  setSelectedCountryCodes: (selectedCountryCodes: string[]) => void;
  disabled?: boolean;
  className?: string;
}

/**
 * @description Country code select component
 * @param {string[]} countryCodes - The country codes to select from.
 * @param {string[]} selectedCountryCodes - The selected country codes.
 * @param {function} setSelectedCountryCodes - Function to set the selected country codes.
 * @param {boolean} disabled - Whether the country code select is disabled.
 */
const CountryCodeSelect: React.FC<CountryCodeSelectProps> = ({
  countryCodes = [],
  selectedCountryCodes,
  setSelectedCountryCodes,
  disabled = false,
  className,
}) => {
  const [open, setOpen] = useState(false);

  const handleCountryCodeToggle = useCallback(
    (countryCode: string) => {
      setSelectedCountryCodes(
        selectedCountryCodes.includes(countryCode)
          ? selectedCountryCodes.filter((c) => c !== countryCode)
          : [...selectedCountryCodes, countryCode],
      );
    },
    [selectedCountryCodes, setSelectedCountryCodes],
  );

  const handleSelectAll = useCallback(() => {
    setSelectedCountryCodes(
      selectedCountryCodes.length === countryCodes.length ? [] : [...countryCodes],
    );
  }, [countryCodes, selectedCountryCodes.length, setSelectedCountryCodes]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-[30%] justify-between", className)}
          disabled={disabled}
        >
          <div className="flex items-center space-x-1">
            {selectedCountryCodes.length > 0 ? (
              selectedCountryCodes.length === countryCodes.length ? (
                <span className="text-sm font-medium">All</span>
              ) : (
                selectedCountryCodes.map((code) => (
                  <span key={code} className="text-sm font-medium">
                    {code}
                  </span>
                ))
              )
            ) : (
              <span>Select countries</span>
            )}
          </div>
          <ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Button
          variant="ghost"
          className="w-full justify-start font-bold"
          onClick={handleSelectAll}
        >
          <div className="flex items-center">
            {selectedCountryCodes.length === countryCodes.length && (
              <CheckIcon className="mr-2 h-4 w-4" />
            )}
            <span>Select All</span>
          </div>
        </Button>
        {countryCodes.map((code) => (
          <Button
            key={code}
            variant="ghost"
            className="w-full justify-start"
            onClick={() => handleCountryCodeToggle(code)}
          >
            <div className="flex items-center">
              {selectedCountryCodes.includes(code) && (
                <CheckIcon className="mr-2 h-4 w-4" />
              )}
              <span>{code}</span>
            </div>
          </Button>
        ))}
      </PopoverContent>
    </Popover>
  );
};

export default React.memo(CountryCodeSelect);
