/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components";
import { Button } from "@/components/ui/button";
import { RadioGroup, RadioGroupItemWithText } from "@/components/ui/radio-group";
import { usePortfolio } from "@/hooks";
import { useCreateProjectStore } from "@/store";
import { Patent, PATENT_STATUSES, PATENT_TYPES } from "@/types";
import React, { useEffect, useState } from "react";
import { AssigneePortfolioTable, CreateStepGroup, PatentNumbersInputCreate } from ".";
import SearchFilters from "../../components/SearchFilters";

/**
 * @description Portfolio patents step displayed in create project flow
 * @param {function} handleSearchAgain - Function to handle the search again button click.
 * @param {function} setInputNumbers - Function to set the input numbers.
 * @param {string[]} inputNumbers - The list of input numbers.
 * @param {boolean} isCompleted - Whether the step is completed.
 */
const PortfolioPatentsStep: React.FC<{
  handleSearchAgain?: () => void;
  setInputNumbers: (numbers: string[]) => void;
  inputNumbers: string[];
  isCompleted: boolean;
}> = ({ setInputNumbers, inputNumbers, isCompleted }) => {
  const [findClicked, setFindClicked] = useState(false);
  const {
    subjectNumbers,
    subjectMode,
    updateSubjectDetails,
    updateSubjectMode,
    updateSubjectNumbers,
  } = useCreateProjectStore();

  const { getPatentsForAssignee } = usePortfolio();
  const [foundPatents, setFoundPatents] = useState<Patent[]>([]);
  const [isAssigneeLoading, setIsAssigneeLoading] = useState(false);

  // Search filters
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [cpcCodes, setCpcCodes] = useState<string[]>([]);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [assignees, setAssignees] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>(PATENT_TYPES);
  const [statuses, setStatuses] = useState<string[]>(PATENT_STATUSES);
  const [maxResults, setMaxResults] = useState<number>(25);

  useEffect(() => {
    setFindClicked(false);
  }, []);

  const handlePortfolioSearchForPatents = async () => {
    if (!assignees) {
      return;
    }
    setIsAssigneeLoading(true);
    setFindClicked(true);

    const fromDateString = fromDate ? fromDate.toISOString().split("T")[0] : "";
    const toDateString = toDate ? toDate.toISOString().split("T")[0] : "";
    const response = await getPatentsForAssignee(
      assignees,
      fromDateString,
      toDateString,
      cpcCodes,
      types,
    );

    setAssignees([]);
    setFromDate(null);
    setToDate(null);
    setCpcCodes([]);
    setTypes([]);

    setIsAssigneeLoading(false);
    setFoundPatents(response.data);
  };

  const handleReset = () => {
    updateSubjectDetails([]);
    updateSubjectNumbers([]);

    setFindClicked(false);
    setIsAssigneeLoading(false);
    setFoundPatents([]);
    setMaxResults(100);
  };

  return (
    <CreateStepGroup
      stepName="Portfolio Subjects"
      isCompleted={isCompleted}
      showSearchAgain={subjectNumbers?.length > 0}
      handleSearchAgain={handleReset}
    >
      <div className="space-y-6">
        {subjectNumbers?.length === 0 && !findClicked && (
          <RadioGroup
            defaultValue={subjectMode}
            onValueChange={(value) =>
              updateSubjectMode(value as "assignee" | "numbers" | "upload")
            }
            className="flex flex-wrap gap-4"
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItemWithText
                value="numbers"
                id="numbers"
                label="Enter patent/application numbers"
              />
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItemWithText
                value="assignee"
                id="assignee"
                label="Search for patents"
              />
            </div>
            {/* {isReference && (
              <div className="flex items-center space-x-2">
                <RadioGroupItemWithText
                  value="upload"
                  id="upload"
                  label="Upload files"
                />
              </div>
            )} */}
          </RadioGroup>
        )}

        {subjectMode === "numbers" && <PatentNumbersInputCreate isInvalidity={false} />}

        {subjectMode === "assignee" && (
          <>
            {foundPatents.length > 0 && (
              <AssigneePortfolioTable
                patents={foundPatents}
                setSelectedReferences={setInputNumbers}
                selectedReferences={inputNumbers}
              />
            )}
            {foundPatents.length === 0 && !isAssigneeLoading && (
              <>
                <SearchFilters
                  assignees={assignees}
                  setAssignees={setAssignees}
                  fromDate={fromDate}
                  setFromDate={setFromDate}
                  toDate={toDate}
                  setToDate={setToDate}
                  maxResults={maxResults}
                  setMaxResults={setMaxResults}
                  cpcCodes={cpcCodes}
                  setCpcCodes={setCpcCodes}
                  keywords={keywords}
                  setKeywords={setKeywords}
                  disabled={false}
                  types={types}
                  setTypes={setTypes}
                  statuses={statuses}
                  setStatuses={setStatuses}
                  isInCreate={true}
                />
                <div className="flex justify-end mt-4">
                  <Button
                    onClick={handlePortfolioSearchForPatents}
                    disabled={!assignees.length}
                  >
                    Search for patents
                  </Button>
                </div>
              </>
            )}
          </>
        )}

        {isAssigneeLoading && <Loader message="Searching for patents" />}
      </div>
    </CreateStepGroup>
  );
};

export default PortfolioPatentsStep;
